.smashApp {
  text-align: center;
  font-family: monospace;
  background-color: grey;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  font-size: larger;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
overflow-y: scroll;
}
.cardContainer{
  margin-top: 0;
  max-height: 100%;
  width: 90vw;
}
.champSelect{
  margin-bottom: 1em;
}
.champImage{
  height: 600;
}
.imageScroll{
  display: flex;
  max-height: 75%;
  align-items: center;
  justify-content: center;
}
.buttons{
  display: flex;
  justify-content: center;
}
.buttonContainer{
  display:flex;
  justify-content: space-between;
}
.nextContainer{
  min-width: 150px;
  max-width: 150px;
  overflow-x: visible;
}
.previousContainer{
  min-width: 150px;
  max-width: 150px;
  overflow-x: visible;
}
.scrollButton {
  padding: 1em;
  height: 50%;
}
.smashButton, .passButton, .notSelectedButton{
  padding: 1.5em 2em;
  width: 10em;
  margin: 1em;
  color: black;
  border: solid 2px;
  border-radius: 16px;
  cursor: pointer;
  font-size: large;
}
.smashButton{
  background-color: greenyellow;
  border-color: green;
}
.smashButton:hover{
  background-color: rgb(143, 194, 66);
  border-color: rgb(0, 82, 0);
  box-shadow: 0.025em 0.025em 0.025em 0.025em;
}
.passButton{
  background-color: coral;
  border-color: red;
}
.passButton:hover{
  background-color: rgb(206, 100, 62);
  border-color: rgb(189, 2, 2);
  box-shadow: 0.025em 0.025em 0.025em 0.025em;
}
.notSelectedButton{
  background-color: lightgrey;
  border-color: black;
}
.notSelectedButton:hover{
  background-color: rgb(150, 148, 148);
  border-color: black;
  box-shadow: 0.025em 0.025em 0.025em 0.025em;
}
.champName{
  font-style: normal;
  line-height: 0;
  font-size: 36px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 900;
}
.skinName{
  font-style: italic;
  font-weight: 300;
  color: rgb(80, 80, 80);
}

.infoBox{
  margin: 0 2em;
}
.showInfoCheck{
  line-height: 0;
  margin:0;
  padding: 0;
}
.showInfoContent{
  padding: 0 2em
}
.statsText{
  text-decoration: underline;
  font-size: larger;
}

.signature{
  color: rgba(0, 0, 0, 0.541);
  position: absolute;
  bottom: 0;
  right: 0;
  font-style: italic;
  font-size: smaller;
}
.signatureLink{
  color: inherit
}
.signatureLink:hover{
  color:blue
}
.changesList{
  border: grey dotted 1px;
  padding-top: 0;
  padding-bottom: 1em;
  text-align: start;
}
.susContainerIn{
  position: absolute;
  display: flex;
min-width: 50%;
opacity: 80%;
pointer-events: none;
  flex-direction: column;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
}
.susContainerOut{
  position: absolute;
  display: flex;
min-width: 50%;
opacity: 0%;
pointer-events: none;
  flex-direction: column;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
}
.susText{
  font-size: xx-large;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-style: oblique;
  color:white
}



.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #a02121;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
  max-height: 65%;
  overflow-y: scroll;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}